import { gql } from "@apollo/client";
import { x } from "@xstyled/styled-components";
import { forwardRef } from "react";

/** @type {React.FC<?>} */
export const Image = forwardRef(
  ({ alt, src, srcSet, width, height, maxWidth, maxHeight, ...props }, ref) => {
    return (
      <x.img
        ref={ref}
        sizes={
          maxWidth ? `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px` : null
        }
        srcSet={srcSet}
        src={src}
        alt={alt || "image"}
        width={width}
        height={height || maxHeight}
        {...(maxWidth ? { w: 1, maxWidth } : null)}
        {...props}
      />
    );
  },
);

export const ImageFixedFragment = gql`
  fragment ImageFixedFragment on ImageFixed {
    srcSet
    src
    width
    height
    alt
  }
`;

export const ImageFluidFragment = gql`
  fragment ImageFluidFragment on ImageFluid {
    srcSet
    src
    maxWidth
    maxHeight
    alt
  }
`;
