import { DocumentNode, gql } from "@apollo/client";
import { NamedExoticComponent, memo } from "react";
import { useField, useForm } from "react-final-form";
import { Button } from "swash/Button";
import { IoAdd } from "swash/Icon";
import { FormLabel } from "swash/form/FormLabel";
import { SelectButton, SelectPopover } from "swash/v2/Select";

import { ExposurePlannedDateField } from "@/components/fields/ExposurePlannedDateField";
import { ArticleExposureIcon } from "@/containers/ExposureIcon";
import { ExposureIndicator } from "@/containers/ExposureIndicator";
import { useHasPermission } from "@/containers/User";
import {
  type ArticleExposure,
  ArticleExposuresSelectList,
  useArticleExposuresSelectState,
} from "@/containers/article/ArticleExposureSelect";
import { ArticleExposureRow } from "@/containers/article/panels/exposure/exposures-section/ArticleExposureRow";

const ExposureFragment = gql`
  fragment ExposureFieldFragment_exposure on Exposure {
    ...ArticleExposureIcon_exposure
    ...ExposureIndicator_exposure
    ...ExposurePlanneDateField_exposure
    newsletter {
      id
      timeDeadline
    }
    periodical {
      id
      periodicity {
        timeDeadline
      }
    }
  }
  ${ArticleExposureIcon.fragments.exposure}
  ${ExposureIndicator.fragments.exposure}
  ${ExposurePlannedDateField.fragments.exposure}
`;

type ArticleExposuresFieldProps = { article: { id: number } };

export const ArticleExposuresField = memo(({ article }) => {
  const form = useForm();

  const field = useField("articleExposures");
  field.input.value;
  const hasWritePermission = useHasPermission(
    ["exposures:edit", "exposures:full"],
    { method: "some" },
  );
  const hasReadOnlyPermission = useHasPermission("exposures:view");

  if (!hasWritePermission || !hasReadOnlyPermission) {
    return null;
  }
  const fieldValue = field.input.value as ArticleExposure[];
  return (
    <div>
      <FormLabel>Expositions de l’article</FormLabel>
      <div className="flex flex-col gap-4" aria-label="Expositions" role="list">
        {fieldValue.map((exposureValue, index) => {
          return (
            <ArticleExposureRow
              key={index}
              article={article}
              articleExposure={exposureValue}
              hasWritePermission={hasWritePermission}
              name={`articleExposures[${index}]`}
              onDelete={() => {
                const newValue = fieldValue
                  .slice(0, index)
                  .concat(fieldValue.slice(index + 1));
                form.change("articleExposures", newValue);
              }}
            />
          );
        })}
      </div>
      {hasWritePermission ? (
        <div className="mt-4 max-w-64">
          <AddArticleExposureButton
            articleId={article.id}
            value={fieldValue || []}
            onChange={(value) => {
              form.change("articleExposures", value);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}) as NamedExoticComponent<ArticleExposuresFieldProps> & {
  fragments: {
    article: DocumentNode;
    articleExposures: DocumentNode;
  };
};

ArticleExposuresField.fragments = {
  article: gql`
    fragment ExposuresField_article on Article {
      id
      ...ExposureIndicator_article
      ...ArticleExposuresSelect_article
    }

    ${ExposureIndicator.fragments.article}
    ${useArticleExposuresSelectState.fragments.article}
  `,
  articleExposures: gql`
    fragment ExposuresField_articleExposures on ArticleExposure {
      fulfilled
      exposureId
      exposure {
        ...ExposureFieldFragment_exposure
      }
      correlatedPlannedDateToArticle
      planning {
        date
        range
      }
      publication {
        ...ExposureIndicator_publication
      }
      ...ExposureIndicator_articleExposure
    }

    ${ExposureFragment}
    ${ExposureIndicator.fragments.articleExposure}
    ${ExposureIndicator.fragments.publication}
  `,
};

const AddArticleExposureButton = ({
  articleId,
  onChange,
  value,
}: {
  articleId: number;
  onChange: (value: ArticleExposure[]) => void;
  value: ArticleExposure[];
}) => {
  const state = useArticleExposuresSelectState({
    articleId,
    onChange,
    value,
  });
  return (
    <>
      <SelectButton asChild store={state.select}>
        <Button variant="secondary" appearance="fill-light">
          <IoAdd /> Suggérer une exposition
        </Button>
      </SelectButton>
      <SelectPopover
        store={state.select}
        modal
        aria-label="Ajouter une exposition"
      >
        <ArticleExposuresSelectList state={state} />
      </SelectPopover>
    </>
  );
};
