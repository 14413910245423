import { type DocumentNode, gql } from "@apollo/client";
import { BubbleExclamation } from "swash/icons";

import {
  ExposureIndicator,
  type ExposureIndicatorProps,
} from "../ExposureIndicator";
import {
  ArticleConfidentialLabel,
  type ArticleConfidentialLabelProps,
} from "./ArticleConfidentialLabel";
import {
  ArticleDescriptionNarrowSection,
  type ArticleDescriptionNarrowSectionProps,
} from "./ArticleDescriptionNarrowSection";
import {
  ArticleDescriptionSigns,
  type ArticleDescriptionSignsProps,
} from "./ArticleDescriptionSigns";
import {
  ArticleEditorialFormatLabel,
  type ArticleEditorialFormatLabelProps,
} from "./ArticleEditorialFormatLabel";
import {
  ArticleEditorialLevelIcon,
  type ArticleEditorialLevelIconProps,
} from "./ArticleEditorialLevelIcon";
import {
  ArticleEditorialTypeLabel,
  type ArticleEditorialTypeLabelProps,
} from "./ArticleEditorialTypeLabel";
import {
  ArticleEvergreenAlert,
  type ArticleEvergreenAlertProps,
} from "./ArticleEvergreenAlert";
import {
  ArticleFeaturedMedia,
  type ArticleFeaturedMediaProps,
} from "./ArticleFeaturedMedia";
import { ArticleIdLabel, ArticleIdLabelProps } from "./ArticleIdLabel";
import {
  ArticleLayoutPricingIndicator,
  type ArticleLayoutPricingIndicatorProps,
} from "./ArticleLayoutPricingIndicator";
import { ArticlePredicates, ArticlePredicatesProps } from "./ArticlePredicates";
import {
  ArticlePublishDateTimeStatus,
  type ArticlePublishDateTimeStatusProps,
} from "./ArticlePublishDateTimeStatus";
import {
  ArticleReferenceLabel,
  type ArticleReferenceLabelProps,
} from "./ArticleReferenceLabel";
import {
  ArticleStatesTooltip,
  type ArticleStatesTooltipProps,
} from "./ArticleStatesTooltip";
import { ArticleTitle, type ArticleTitleProps } from "./ArticleTitle";
import { ArticleStateIcon } from "./state/ArticleStateIcon";
import {
  type GetArticleStatusColorArticle,
  getArticleStatusColor,
} from "./util/status";

export type ArticleHoverCardProps = {
  article: GetArticleStatusColorArticle &
    ArticleLayoutPricingIndicatorProps["article"] &
    ArticleStatesTooltipProps["article"] &
    ArticleEditorialLevelIconProps["article"] &
    ArticleTitleProps["article"] &
    ArticleDescriptionNarrowSectionProps["article"] &
    ArticleFeaturedMediaProps["article"] &
    ArticlePublishDateTimeStatusProps["article"] &
    ArticleConfidentialLabelProps["article"] &
    ArticleReferenceLabelProps["article"] &
    ArticleEditorialFormatLabelProps["article"] &
    ArticleEditorialTypeLabelProps["article"] &
    ArticleIdLabelProps["article"] &
    ArticleDescriptionSignsProps["article"] &
    ArticleEvergreenAlertProps["article"] &
    ArticlePredicatesProps["article"] & {
      signature: string;
      hasNotes: boolean;
      articleExposures: {
        nodes: ExposureIndicatorProps["articleExposure"][];
      };
    };
};
export type ArticleHoverCardFragments = {
  article: DocumentNode;
};

export const ArticleHoverCard: React.FC<ArticleHoverCardProps> & {
  fragments: ArticleHoverCardFragments;
} = ({ article }) => {
  const [state] = article.states;
  return (
    <div
      className="flex flex-row gap-4 rounded border bg-white p-4 shadow"
      style={{
        borderLeftWidth: 3,
        borderLeftColor: getArticleStatusColor(article),
      }}
    >
      <div className="flex flex-col items-center justify-start gap-2 pt-1">
        <ArticleLayoutPricingIndicator article={article} />
        {state && (
          <ArticleStatesTooltip article={article}>
            <ArticleStateIcon state={state} />
          </ArticleStatesTooltip>
        )}
        <ArticleEditorialLevelIcon article={article} />
      </div>
      <div className="flex flex-grow flex-col gap-4">
        <div className="flex flex-row items-start gap-2">
          <h3 className="flex-grow text-base leading-5 [&>*:first-child]:before:hidden [&>*]:before:px-1.5 [&>*]:before:text-grey-border-light [&>*]:before:content-['|']">
            <span>
              <ArticleTitle article={article} />
            </span>
            {article.narrowSection && (
              <ArticleDescriptionNarrowSection article={article} />
            )}
          </h3>
          <ArticleFeaturedMedia
            article={article}
            className="w-[114px] rounded-sm"
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row flex-wrap items-center justify-start gap-x-2 gap-y-0.5 text-xs">
            <ArticlePublishDateTimeStatus article={article} />
            <ArticleConfidentialLabel article={article} />
            <ArticleReferenceLabel article={article} />
            <ArticleEditorialFormatLabel article={article} />
            <ArticleEditorialTypeLabel article={article} />
            <div className="basis-full" />
            <ArticleIdLabel article={article} />
            <ArticleDescriptionSigns article={article} disabled />
            <ArticleEvergreenAlert article={article} />
            <ArticlePredicates article={article} />
          </div>
          {article.signature && (
            <div className="text-sm text-grey-on">Par {article.signature}</div>
          )}
          {(article.hasNotes || article.articleExposures.nodes.length) && (
            <div className="flex flex-row justify-start gap-x-2 gap-y-1">
              {article.hasNotes && <BubbleExclamation />}
              {article.articleExposures.nodes.map((articleExposure) => (
                <ExposureIndicator
                  key={articleExposure.gid}
                  articleExposure={articleExposure}
                  article={article}
                  exposure={articleExposure.exposure}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ArticleHoverCard.fragments = {
  article: gql`
    fragment ArticleHoverCard_article on Article {
      ...getArticleStatusColor
      ...ArticleLayoutPricingIndicator_article
      ...ArticleStatesTooltip_article
      states {
        ...ArticleStateIcon_state
      }
      ...ArticleEditorialLevelIcon_article
      ...ArticleTitle_article
      ...ArticleDescriptionNarrowSection_article
      ...ArticleFeaturedMedia_article
      ...ArticlePublishDateTimeStatus_article
      ...ArticleConfidentialLabel_article
      ...ArticleReferenceLabel_article
      ...ArticleEditorialFormatLabel_article
      ...ArticleEditorialTypeLabel_article
      ...ArticleIdLabel_article
      ...ArticleDescriptionSigns_article
      ...ArticleEvergreenAlert_article
      ...ArticlePredicates_article
      signature
      hasNotes
      ...ExposureIndicator_article
      articleExposures {
        nodes {
          ...ExposureIndicator_articleExposure
          exposure {
            ...ExposureIndicator_exposure
          }
          publication {
            ...ExposureIndicator_publication
          }
        }
      }
    }

    ${getArticleStatusColor.fragment}
    ${ArticleLayoutPricingIndicator.fragments.article}
    ${ArticleStatesTooltip.fragments.article}
    ${ArticleStateIcon.fragments.state}
    ${ArticleEditorialLevelIcon.fragments.article}
    ${ArticleTitle.fragments.article}
    ${ArticleDescriptionNarrowSection.fragments.article}
    ${ArticleFeaturedMedia.fragments.article}
    ${ArticlePublishDateTimeStatus.fragments.article}
    ${ArticleConfidentialLabel.fragments.article}
    ${ArticleReferenceLabel.fragments.article}
    ${ArticleEditorialFormatLabel.fragments.article}
    ${ArticleEditorialTypeLabel.fragments.article}
    ${ArticleIdLabel.fragments.article}
    ${ArticleDescriptionSigns.fragments.article}
    ${ArticleEvergreenAlert.fragments.article}
    ${ArticlePredicates.fragments.article}
    ${ExposureIndicator.fragments.articleExposure}
    ${ExposureIndicator.fragments.article}
    ${ExposureIndicator.fragments.publication}
    ${ExposureIndicator.fragments.exposure}
  `,
};
